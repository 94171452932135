
import { IProduct } from '@/interfaces/product';
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import Filters from '@/components/Filters.vue';
import Editable from '@/components/Editable.vue';

export default defineComponent({
    components: { Filters, Editable },
    mixins: [Base],
    data() {
        return {

            selected: [],
            isAdvancedFiltersOpened: false,
            items: {
                data: [] as Array<IProduct>,
                meta: {
                    total: 0,
                },
            },
            columns: [
                {
                    visible: false,
                    label: 'Base price',
                    field: 'base_price',
                    position: 'centered',
                    editable: true,
                    suffix: '€',
                },
                {

                    visible: false,
                    label: 'Unit price',
                    field: 'unit_price',
                    position: 'centered',
                    editable: true,
                    suffix: '€',
                },
                {

                    visible: false,
                    label: 'Forms default base',
                    field: 'forms_default_base',
                    position: 'centered',
                    editable: true,
                    suffix: '€',
                },
                {

                    visible: false,
                    label: 'Forms default rate',
                    field: 'forms_default_rate',
                    position: 'centered',
                    editable: true,
                    suffix: '€',
                },
                {

                    visible: false,
                    label: 'Forms default rate2',
                    field: 'forms_default_rate2',
                    position: 'centered',
                    editable: true,
                    suffix: '€',
                },
            ],
            params: {
                page: 1,
                filter: '',
                type: 'product',
                sortBy: 'created_at',
                platform: undefined as any,
                country: undefined as any,
                category: undefined as any,
                client: undefined as any,
                orderBy: 'desc',
                has_warning: false,
                has_spoq_id: false,
                has_forms_id: false,
                has_forms_report_type: false,
                has_duplicates_code: false,
                active: true,
            },
        };
    },

    mounted() {
        this.getItems();
    },
    methods: {
        bulk(action: string, params: any) {
            this.post('products/bulk', {
                params,
                ids: this.selected.map((s: object) => s.id),
                action,
            })
                .then(() => {
                    this.selected = [];
                    this.getItems();
                });
        },
        getItems(page: number = 1) {
            this.params.page = page;
            this.get('products', {
                params: {
                    ...this.params,
                    platform_id: this.params.platform ? this.params.platform.id : null,
                    country_id: this.params.country ? this.params.country.id : null,
                    category_id: this.params.category ? this.params.category.id : null,
                    client_id: this.params.client ? this.params.client.id : null,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
    },
});
